
import { defineComponent, h, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { registrationSimpleStore } from "@/web/store/registrationStore";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import isEmpty from "lodash/isEmpty";
import routeNames from "@/shared/router/routeNames";
import { config } from "@/shared/utils/config";
import routePaths from "@/web/router/routePaths";
import FullPageCardLayout from "@/shared/components/Layouts/FullPageCardLayout.vue";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import SearchStakingID from "@/shared/components/SearchStakingID.vue";
import InputPassword from "@/shared/components/Forms/InputPassword.vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import {
  Register,
  RegisterVariables,
  Register_register_Authentication,
} from "@/api/onboarding/__generated__/Register";
import useCustomMutation from "@/api/graphqlClient/useCustomMutation";
import { registerGql } from "@/api/onboarding/register";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { apiErrorCodes } from "@/shared/utils/constants";
import SubmitButton from "@/shared/components/Buttons/SubmitButton.vue";
import notification from "ant-design-vue/lib/notification";
import RegisterAccountExistNotification from "@/web/views/Register/RegisterAccountExistNotification.vue";
import { SUPPORTED_EXTERNAL_WALLETS_OPTIONS } from "@/shared/utils/cardano";
import { useExternalWallet } from "@/shared/composables/wallet/useExternalWallet";
import { useAuthentication } from "@/shared/composables/useAuthentication";
import InputCheckbox from "@/shared/components/Forms/InputCheckbox.vue";
import { getOrCreateDeviceId } from "@/shared/utils/browser";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const register = useCustomMutation<Register, RegisterVariables>(
      registerGql
    );
    const { registerWithExternalWallet, walletLoading } = useExternalWallet();
    const { experimentalLoginStateUpdate } = useAuthentication();

    const submitting = ref(false);

    const formSignUpState = reactive({
      stakingId: "",
      password: "",
      doNotExpireSession: true,
    });

    const handleSubmit = async () => {
      const registerResponse = await register.mutate({
        input: {
          stakingKeyHash: formSignUpState.stakingId,
          password: formSignUpState.password,
          doNotExpireSession: formSignUpState.doNotExpireSession,
          deviceId: getOrCreateDeviceId(),
        },
      });

      /**
       * Don't show account registered error because we will have a separate handling
       */
      const parsedResponse = parseGqlResponse<Register_register_Authentication>(
        "Authentication",
        registerResponse,
        apiErrorCodes.ACCOUNT_REGISTERED
      );

      console.log("parsedResponse", parsedResponse);

      const user = parsedResponse?.data?.user;
      const authToken = parsedResponse.data?.authToken;

      if (!isEmpty(parsedResponse.error?.errors)) {
        /**
         * Check if the error is account already registered
         * This notification uses a functional tsx component to render the notification description
         */
        const accountExists = parsedResponse.error?.errors.some(
          (error) => error.__typename === "AlreadyRegisteredError"
        );
        if (accountExists) {
          /**
           * notificationKey will be used to close notification window when clicking login link
           */
          const notificationKey = "existNotification";
          notification.warning({
            message: t("Already registered"),
            description: h(RegisterAccountExistNotification, {
              notificationKey,
            }),
            key: notificationKey,
          });
          return;
        }
      }

      /**
       * If user id is exist, it means that the user is created
       */
      if (user?.id) {
        await store.dispatch("authUpdate", {
          isAuthenticated: false, // at this point, authentication is not yet full
          token: authToken?.token,
          expiry: authToken?.expiry,
          userId: user?.id,
          stakingKeyHash: user?.wallet?.stakingKeyHash,
          avatar40: user?.avatar40,
          avatar80: user?.avatar80,
          avatar400: user?.avatar400,
        });

        await store.dispatch("loginSuccess", user);

        registrationSimpleStore.stakingId = user?.username;
        registrationSimpleStore.pendingAction = user?.pendingAction;
      }
    };

    //  Separated login with other wallet, separate API later
    const handleSubmitThirdParty = async (walletName) => {
      try {
        const response = await registerWithExternalWallet(
          walletName,
          formSignUpState.doNotExpireSession
        );
        /**
         * If the user is already registered
         * Check if it's requiring to redirect to login and handle 2fa
         */
        if (
          response &&
          response.redirectToLogin &&
          response.twoFactorRequired
        ) {
          experimentalLoginStateUpdate({
            twoFactorRequired: response.twoFactorRequired,
          });
          return router.push({ name: routeNames.login });
        }
      } catch (error) {
        console.log("handleSubmitThirdParty:error", error);
      }
    };

    // On staking id found, assign to form state
    const stakingIdFound = (stakingId: string) => {
      console.log("stakingId", stakingId);
      formSignUpState.stakingId = stakingId;
    };

    return {
      t,
      register,
      submitting,
      walletLoading,
      formSignUpState,
      handleSubmit,
      routePaths,
      config,
      handleSubmitThirdParty,
      SUPPORTED_EXTERNAL_WALLETS_OPTIONS,
      stakingIdFound,
    };
  },
  methods: {
    makeRequiredRule,
  },
  components: {
    FormGroup,
    InputPassword,
    FullPageCardLayout,
    InputText,
    SearchStakingID,
    SubmitButton,
    InputCheckbox,
  },
});

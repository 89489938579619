import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "register-step-1__login-methods" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_SearchStakingID = _resolveComponent("SearchStakingID")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputPassword = _resolveComponent("InputPassword")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_InputCheckbox = _resolveComponent("InputCheckbox")!
  const _component_SubmitButton = _resolveComponent("SubmitButton")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_FullPageCardLayout = _resolveComponent("FullPageCardLayout")!

  return (_openBlock(), _createBlock(_component_FullPageCardLayout, {
    class: "register-step-1",
    title: _ctx.t('Sign up')
  }, {
    subtitle: _withCtx(() => [
      _createVNode(_component_a_typography_paragraph, { class: "whitespace-normal" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("Already have an account?")) + " ", 1),
          _createVNode(_component_router_link, {
            to: _ctx.routePaths.login
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Log in")), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_FormGroup, {
        class: "register-form-1",
        "wrapper-col": { span: 24 },
        model: _ctx.formSignUpState,
        layout: "vertical",
        loading: _ctx.register.loading.value,
        onFinish: _ctx.handleSubmit
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputText, {
            label: _ctx.t('Staking Key Hash'),
            name: "stakingId",
            placeholder: "ba12d7cfa46e187426a72655fc26e632f...",
            loading: _ctx.submitting,
            value: _ctx.formSignUpState.stakingId,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formSignUpState.stakingId) = $event)),
            rules: [_ctx.makeRequiredRule(_ctx.t('Staking Key Hash is required'))]
          }, {
            labelEnd: _withCtx(() => [
              _createVNode(_component_SearchStakingID, { onStakingIdFound: _ctx.stakingIdFound }, {
                trigger: _withCtx(({ open }) => [
                  _createVNode(_component_a_button, {
                    onClick: open,
                    type: "link",
                    size: "small",
                    disabled: _ctx.register.loading.value
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Don’t know it?")), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick", "disabled"])
                ]),
                _: 1
              }, 8, ["onStakingIdFound"])
            ]),
            _: 1
          }, 8, ["label", "loading", "value", "rules"]),
          _createVNode(_component_InputPassword, {
            label: "Password",
            name: "password",
            placeholder: "Enter password",
            value: _ctx.formSignUpState.password,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formSignUpState.password) = $event)),
            rules: [_ctx.makeRequiredRule(_ctx.t('Password is required'))],
            help: 
          _ctx.t(
            'At least 8 characters, lower and upper case letters (a-z), numbers (0-9)'
          )
        
          }, null, 8, ["value", "rules", "help"]),
          _createVNode(_component_i18n_t, {
            keypath: "By signing up, you agree to {termsOfService}",
            tag: "div",
            class: "ant-typography-paragraph my-6"
          }, {
            termsOfService: _withCtx(() => [
              _createElementVNode("span", null, [
                _createElementVNode("a", {
                  href: _ctx.config.termsWebsiteUseUrl,
                  target: "_blank"
                }, _toDisplayString(_ctx.t("our terms of service")), 9, _hoisted_1)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_InputCheckbox, {
            checkLabel: _ctx.t('Keep me logged in'),
            name: "doNotExpireSession",
            value: _ctx.formSignUpState.doNotExpireSession,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formSignUpState.doNotExpireSession) = $event))
          }, null, 8, ["checkLabel", "value"]),
          _createVNode(_component_SubmitButton, {
            loading: _ctx.register.loading.value,
            disabled: !!_ctx.walletLoading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Continue")), 1)
            ]),
            _: 1
          }, 8, ["loading", "disabled"])
        ]),
        _: 1
      }, 8, ["model", "loading", "onFinish"]),
      _createVNode(_component_a_divider, { class: "card-internal-divider" }),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.SUPPORTED_EXTERNAL_WALLETS_OPTIONS, (app) => {
          return (_openBlock(), _createBlock(_component_a_button, {
            key: app.value,
            onClick: ($event: any) => (_ctx.handleSubmitThirdParty(app.value)),
            block: "",
            disabled: !!_ctx.walletLoading && _ctx.walletLoading !== app.value,
            loading: _ctx.walletLoading === app.value
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Register with {app}", { app: app.label })), 1)
            ]),
            _: 2
          }, 1032, ["onClick", "disabled", "loading"]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}
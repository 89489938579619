
import { defineComponent, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import FullPageCardLayout from "@/shared/components/Layouts/FullPageCardLayout.vue";
import TopHeaderLogout from "./TopHeaderLogout.vue";
import TwoFactorAuthenticationSetup from "@/shared/components/Authenticator/TwoFactorAuthenticationSetup.vue";
import routeNames from "@/web/router/routeNames";
import { registrationSimpleStore } from "@/web/store/registrationStore";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();

    // For Revoke Step, user goes back to 2FA Setup on /onboarding
    onMounted(() => {
      const stakingId = store.state.userInfo.username;
      // Only assign staking Id if the store has value, to avoid assigning an empty string
      if (stakingId) {
        registrationSimpleStore.stakingId = stakingId;
      }
    });

    const handleFinish = async () => {
      console.log("handleFinish");
      await router.push({ name: routeNames.profile });
    };

    return {
      t,
      handleFinish,
      registrationSimpleStore,
    };
  },
  components: {
    FullPageCardLayout,
    TopHeaderLogout,
    TwoFactorAuthenticationSetup,
  },
});

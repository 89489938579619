import gql from "graphql-tag";

export const getStakingKeyHashFromAddressGql = gql`
  query GetStakingKeyHashFromAddress(
    $input: GetStakingKeyHashFromAddressInput!
  ) {
    getStakingKeyHashFromAddress(input: $input) {
      ... on GetStakingKeyHashFromAddressDetail {
        stakingKeyHash
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;


import { defineComponent, h } from "vue";
import notification from "ant-design-vue/lib/notification";
import { i18nTranslate } from "@/plugins/i18n";
import routePaths from "@/shared/router/routePaths";
import currentRouter from "@/shared/router/currentRouter";

/**
 * This is a functional tsx component
 */
export default defineComponent({
  props: {
    notificationKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const handleLoginRedirect = () => {
      notification.close(props.notificationKey);
      currentRouter.push(routePaths.login);
    };
    /**
     * i18nTranslate is required for translation instead of the composable because this component
     * doesn't have an instance, that is the very reason why currentRouter is needed
     */
    return () =>
      h("span", [
        i18nTranslate("The provided Staking Key Hash is already registered."),
        h(
          "span",
          {
            class: "cursor-pointer underline mx-1",
            onClick: handleLoginRedirect,
          },
          i18nTranslate("Click Here")
        ),
        h("span", i18nTranslate("to login instead.")),
      ]);
  },
});

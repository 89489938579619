import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopHeaderLogout = _resolveComponent("TopHeaderLogout")!
  const _component_TwoFactorAuthenticationSetup = _resolveComponent("TwoFactorAuthenticationSetup")!
  const _component_FullPageCardLayout = _resolveComponent("FullPageCardLayout")!

  return (_openBlock(), _createBlock(_component_FullPageCardLayout, { class: "register-step-4" }, {
    "header-right": _withCtx(() => [
      _createVNode(_component_TopHeaderLogout, {
        alwaysShow: !!_ctx.registrationSimpleStore.stakingId
      }, null, 8, ["alwaysShow"])
    ]),
    "custom-body": _withCtx(() => [
      _createVNode(_component_TwoFactorAuthenticationSetup, {
        generate2fa: "",
        onFinish: _ctx.handleFinish
      }, null, 8, ["onFinish"])
    ]),
    _: 1
  }))
}

import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { config } from "@/shared/utils/config";
import { registrationSimpleStore } from "@/web/store/registrationStore";
import FullPageCardLayout from "@/shared/components/Layouts/FullPageCardLayout.vue";
import TitleValue from "@/shared/components/TitleValue.vue";
import TopHeaderLogout from "./TopHeaderLogout.vue";
import ClockIcon from "@/assets/clock.svg";
import WarningIcon from "@/assets/warning-icon.svg";
import SuccessIcon from "@/assets/success-icon.svg";
import SubmitButton from "@/shared/components/Buttons/SubmitButton.vue";
import { useWalletConfirmationDetail } from "@/shared/composables/wallet/useWalletConfirmationDetail";
import { convertLovelaceToAda } from "@/web/utils/walletUtils";
import { maskWalletAddress } from "@/shared/utils/stringHelper";

export enum ConfirmationStatus {
  REGISTERED = "REGISTERED",
  PENDING = "PENDING",
  TIMEOUT = "TIMEOUT",
  INCORRECT_AMOUNT = "INCORRECT_AMOUNT",
  SUCCESS = "SUCCESS",
}

export default defineComponent({
  props: {
    deadline: {
      type: Number,
      default: Date.now() + 1000 * 60 * 30,
    },
  },
  setup() {
    const { t } = useI18n();
    const confirmStatus = ref(ConfirmationStatus.REGISTERED);
    const submitting = ref(false);

    const onTimeout = () => {
      // TODO: update when timeout
      console.log("timeout");
      // If status is success, no need to set the timeout error
      if (confirmStatus.value != ConfirmationStatus.SUCCESS) {
        confirmStatus.value = ConfirmationStatus.TIMEOUT;
      }
    };
    const {
      walletDetail,
      walletDetailLoading,
      handleConfirmWallet,
      confirmWalletLoading,
      handleRefetchWalletConfirmationDetail,
    } = useWalletConfirmationDetail(null, true);

    console.log("walletdetail", walletDetail);
    const parsedConfirmationAmount = computed(() =>
      convertLovelaceToAda(
        walletDetail.value?.walletConfirmationAttempt?.confirmationAmount
      )
    );

    const handleRetry = async () => {
      await handleRefetchWalletConfirmationDetail();

      // reset to initial state
      confirmStatus.value = ConfirmationStatus.REGISTERED;
    };

    const moneySent = async () => {
      try {
        await handleConfirmWallet({
          walletConfirmationAttemptId: walletDetail.value
            ?.walletConfirmationAttempt?.id as string,
        });
        confirmStatus.value = ConfirmationStatus.SUCCESS;
      } catch (error) {
        console.log("Error on Register STEP2", error);
      }
    };

    return {
      t,
      config,
      onTimeout,
      registrationSimpleStore,
      walletDetail,
      walletDetailLoading,
      parsedConfirmationAmount,
      moneySent,
      confirmWalletLoading,
      ClockIcon,
      WarningIcon,
      SuccessIcon,
      // Status
      confirmStatus,
      ConfirmationStatus,
      // Loading
      submitting,
      /** Since these 3 uses the same component,
       * only update the message value
       * depending on error */
      alertMessage: computed(() => {
        if (confirmStatus.value == ConfirmationStatus.TIMEOUT) {
          // If Time is Over, return time is over alert
          return t("Time is over! We didn’t receive your crypto");
        } else if (confirmStatus.value == ConfirmationStatus.INCORRECT_AMOUNT) {
          // If Amount is Incorrect, return incorrect ADA alert
          return t("Error! We have received incorrect ADA amount");
        } else if (confirmStatus.value == ConfirmationStatus.SUCCESS) {
          // Success
          return t("We have received your crypto!\nWallet has been confirmed");
        }
        return "";
      }),
      handleRetry,
    };
  },
  methods: {
    maskWalletAddress,
  },
  components: { FullPageCardLayout, TitleValue, TopHeaderLogout, SubmitButton },
});

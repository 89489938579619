import { getStakingKeyHashFromAddressGql } from "@/api/onboarding/getStakingKeyHashFromAddress";
import { GetStakingKeyHashFromAddress_getStakingKeyHashFromAddress_GetStakingKeyHashFromAddressDetail } from "@/api/onboarding/__generated__/GetStakingKeyHashFromAddress";
import { useQuery } from "@vue/apollo-composable";
import { computed, reactive } from "vue";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { GetStakingKeyHashFromAddressInput } from "../../../__generated__/globalTypes";
import { apiErrorCodes } from "../utils/constants";

export const emptyStakingState = {
  address: "",
};

/**
 * Get Staking Key Hash From Address composable
 *
 */
export function useFindStakingHashKey() {
  const stakingState =
    reactive<GetStakingKeyHashFromAddressInput>(emptyStakingState);

  // Get Staking Key Hash From Address Query
  const {
    result, // result value
    loading: findStakingHashKeyLoading, // loading state
  } = useQuery(
    getStakingKeyHashFromAddressGql,
    () => ({
      input: {
        address: String(stakingState.address),
      },
    }),
    () => ({
      enabled: !!stakingState.address,
      fetchPolicy: "no-cache",
    })
  );

  // Get Staking Key Hash From Address Response Data
  const stakingHashKeyOfAddress = computed(() => {
    return parseGqlResponse<GetStakingKeyHashFromAddress_getStakingKeyHashFromAddress_GetStakingKeyHashFromAddressDetail>(
      "GetStakingKeyHashFromAddressDetail",
      result.value,
      apiErrorCodes.INTERNAL_ERROR
    );
  });

  return {
    stakingState,
    findStakingHashKeyLoading,
    stakingHashKeyOfAddress,
  };
}


import { defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import { message } from "ant-design-vue";
import { useFindStakingHashKey } from "@/shared/composables/useFindStakingHashKey";
import { useVModel } from "vue-composable";
import InputText from "@/shared/components/Forms/InputText.vue";

export default defineComponent({
  props: {
    value: {
      type: String,
    },
  },
  emits: ["staking-id-found"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const incomingAddress = ref();
    const stakingId = useVModel(props, "value");
    const visible = ref<boolean>(false);

    const { stakingState, findStakingHashKeyLoading, stakingHashKeyOfAddress } =
      useFindStakingHashKey();

    // Trigger fetching of query on find
    const findStakingHashKey = (address: string) => {
      if (!address) {
        message.info(t("Address is required"));
        return;
      }

      // Assigning Object state will trigger the query every type of user
      stakingState.address = address;
    };

    // Watch the response data of API
    watch(stakingHashKeyOfAddress, (stakingId) => {
      if (stakingId?.data) {
        message.info(t("Successfully found Staking Key Hash."));
        closeToolTip();
        emit("staking-id-found", stakingId?.data?.stakingKeyHash);
        incomingAddress.value = "";
        stakingState.address = "";
        return;
      }

      message.info(
        t(
          "Staking Key Hash not found. Please make sure a proper wallet address is provided."
        )
      );
    });

    const closeToolTip = () => {
      visible.value = false;
    };

    return {
      t,
      makeRequiredRule,
      incomingAddress,
      findStakingHashKeyLoading,
      findStakingHashKey,
      stakingId,
      visible,
      closeToolTip,
      stakingHashKeyOfAddress,
    };
  },
  components: { InputText },
});

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "register-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RegisterStep2 = _resolveComponent("RegisterStep2")!
  const _component_RegisterStep3 = _resolveComponent("RegisterStep3")!
  const _component_RegisterStep4 = _resolveComponent("RegisterStep4")!
  const _component_RegisterStep1 = _resolveComponent("RegisterStep1")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.pendingAction === _ctx.UserPendingAction.WALLET_CONFIRMATION)
      ? (_openBlock(), _createBlock(_component_RegisterStep2, { key: 0 }))
      : (_ctx.pendingAction === _ctx.UserPendingAction.PROFILE_SETUP)
        ? (_openBlock(), _createBlock(_component_RegisterStep3, { key: 1 }))
        : (_ctx.pendingAction === _ctx.UserPendingAction.TWO_FACTOR_AUTH_SETUP)
          ? (_openBlock(), _createBlock(_component_RegisterStep4, { key: 2 }))
          : (_openBlock(), _createBlock(_component_RegisterStep1, { key: 3 }))
  ]))
}
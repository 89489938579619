import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!

  return (_openBlock(), _createBlock(_component_a_tooltip, {
    placement: "bottomRight",
    visible: _ctx.visible,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.visible) = $event)),
    trigger: "click",
    overlayClassName: "basic-tooltip"
  }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.t(
            "If you don't know your Staking Key Hash please provide any incoming address that you've used in the past and we will search for your Staking Key Hash"
          )), 1),
      _createVNode(_component_a_row, {
        justify: "center",
        gutter: 16,
        class: "flex"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, { class: "flex-1" }, {
            default: _withCtx(() => [
              _createVNode(_component_InputText, {
                name: "incomingAddress",
                placeholder: "ba12d7cfa46e187426a726...",
                value: _ctx.incomingAddress,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.incomingAddress) = $event)),
                disabled: _ctx.findStakingHashKeyLoading
              }, null, 8, ["value", "disabled"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                size: "small",
                class: "mt-2 text-center",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.findStakingHashKey(_ctx.incomingAddress))),
                loading: _ctx.findStakingHashKeyLoading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Find")), 1)
                ]),
                _: 1
              }, 8, ["loading"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "trigger")
    ]),
    _: 3
  }, 8, ["visible"]))
}

import { computed, defineComponent, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { registrationSimpleStore } from "@/web/store/registrationStore";
import RegisterStep1 from "./RegisterStep1.vue";
import RegisterStep2 from "./RegisterStep2.vue";
import RegisterStep3 from "./RegisterStep3.vue";
import RegisterStep4 from "./RegisterStep4.vue";
import { UserPendingAction } from "../../../../__generated__/globalTypes";
import { useAccount } from "@/shared/composables/Profile/useAccount";

export default defineComponent({
  components: {
    RegisterStep1,
    RegisterStep2,
    RegisterStep3,
    RegisterStep4,
  },
  setup() {
    const { t } = useI18n();
    const { isPartialAuthenticated } = useAccount();
    const store = useStore();
    const pendingAction = computed(() => registrationSimpleStore.pendingAction);

    /**
     * logic for redirection should strictly depend on
     * the pending_action value, not the previous screen.
     * this way, even if we switch the flow on the backend
     * (by updating the pending action), the FE flow will change accordingly.
     */
    watch([pendingAction], ([pending]) => {
      if (pending == UserPendingAction.A_) {
        store.state.auth.isAuthenticated = true;
      }
    });

    onMounted(() => {
      /**
       * On mount check if the user is partially authenticated and there's pending action
       * Save it to register store so he/she will be able to continue from where he/she left off.
       */
      if (
        isPartialAuthenticated.value &&
        store.state.userInfo.pendingAction &&
        store.state.userInfo.pendingAction !== UserPendingAction.A_
      ) {
        registrationSimpleStore.pendingAction =
          store.state.userInfo.pendingAction;
      }
    });

    return {
      t,
      UserPendingAction,
      registrationSimpleStore,
      pendingAction,
    };
  },
});


import { computed, defineComponent, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { config } from "@/shared/utils/config";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import FullPageCardLayout from "@/shared/components/Layouts/FullPageCardLayout.vue";
import TopHeaderLogout from "./TopHeaderLogout.vue";
import AvatarUploader from "@/shared/components/AvatarUploader.vue";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import { getImageFromUrl } from "@/shared/utils/fileUtils";
import { useProfile } from "@/shared/composables/Profile/useProfile";

const enum SubmitMethods {
  NORMAL = "NORMAL",
  ANONYMOUS = "ANONYMOUS",
}

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const avatarFile = ref();
    const submitMethod = ref(SubmitMethods.NORMAL);

    const { setupProfileLoading, handleProfileSetup } = useProfile();

    const formSignUpState = reactive({
      fullName: "",
    });

    const handleSubmit = async () => {
      submitMethod.value = SubmitMethods.NORMAL;
      await handleProfileSetup({
        name: formSignUpState.fullName,
        avatar: avatarFile.value,
      });
    };

    const handleSubmitAnonymous = async () => {
      submitMethod.value = SubmitMethods.ANONYMOUS;
      await handleProfileSetup({
        name: "",
        avatar: null,
      });
    };

    const handleAvatarChange = async (url) => {
      console.log("handleAvatarChange", url);
      const file = await getImageFromUrl(url);
      avatarFile.value = file;
    };

    return {
      t,
      setupProfileLoading,
      submittingNormal: computed(
        () =>
          submitMethod.value === SubmitMethods.NORMAL &&
          setupProfileLoading.value
      ),
      submittingAnonymous: computed(
        () =>
          submitMethod.value === SubmitMethods.ANONYMOUS &&
          setupProfileLoading.value
      ),
      makeRequiredRule,
      config,
      formSignUpState,
      handleSubmit,
      handleSubmitAnonymous,
      handleAvatarChange,
    };
  },
  components: {
    FullPageCardLayout,
    TopHeaderLogout,
    AvatarUploader,
    FormGroup,
    InputText,
  },
});

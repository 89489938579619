import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopHeaderLogout = _resolveComponent("TopHeaderLogout")!
  const _component_AvatarUploader = _resolveComponent("AvatarUploader")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_FullPageCardLayout = _resolveComponent("FullPageCardLayout")!

  return (_openBlock(), _createBlock(_component_FullPageCardLayout, {
    class: "register-step-3",
    title: 
      _ctx.t('Welcome to {merchantBrand}!', {
        merchantBrand: _ctx.config.merchantBrand,
      })
    
  }, {
    "header-right": _withCtx(() => [
      _createVNode(_component_TopHeaderLogout)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_AvatarUploader, {
        class: "mb-6",
        onChange: _ctx.handleAvatarChange
      }, null, 8, ["onChange"]),
      _createVNode(_component_FormGroup, {
        class: "register-form-3",
        "wrapper-col": { span: 24 },
        model: _ctx.formSignUpState,
        layout: "vertical",
        loading: _ctx.setupProfileLoading,
        onFinish: _ctx.handleSubmit
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputText, {
            label: _ctx.t('Your Name'),
            name: "fullName",
            placeholder: "John Smeeth",
            value: _ctx.formSignUpState.fullName,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formSignUpState.fullName) = $event)),
            rules: [_ctx.makeRequiredRule(_ctx.t('Name is required'))]
          }, null, 8, ["label", "value", "rules"]),
          _createVNode(_component_a_typography_paragraph, { class: "small mb-6" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t(
            "This data will be visible to any service where you will login with {merchantBrand}",
            {
              merchantBrand: _ctx.config.merchantBrand,
            }
          )), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_button, {
            type: "primary",
            "html-type": "submit",
            loading: _ctx.submittingNormal,
            disabled: _ctx.submittingAnonymous,
            class: "mr-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Continue")), 1)
            ]),
            _: 1
          }, 8, ["loading", "disabled"]),
          _createVNode(_component_a_button, {
            onClick: _ctx.handleSubmitAnonymous,
            loading: _ctx.submittingAnonymous,
            disabled: _ctx.submittingNormal,
            style: {"border":"none"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Stay anonymous")), 1)
            ]),
            _: 1
          }, 8, ["onClick", "loading", "disabled"])
        ]),
        _: 1
      }, 8, ["model", "loading", "onFinish"])
    ]),
    _: 1
  }, 8, ["title"]))
}